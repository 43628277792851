<script lang="ts">
    import { onMount } from 'svelte';
    import { PUBLIC_PWA_DISABLED } from '$env/static/public';
    import { base } from '$app/paths';
    import { dev } from '$app/environment';

    const isPwaEnabled = PUBLIC_PWA_DISABLED !== 'true';

    let ReloadPrompt: any; // PWA 리로드를 체크하는 프롬프트
    onMount(async () => {
        !dev && isPwaEnabled && (ReloadPrompt = (await import('./ReloadPrompt.svelte')).default);
    });
</script>

<svelte:head>
    {#if isPwaEnabled}
        <link rel="manifest" href={`${base}/manifest.webmanifest`} />
    {/if}
</svelte:head>

<!-- PWA 리로드 프롬프트 -->
{#if ReloadPrompt}
    <svelte:component this={ReloadPrompt} />
{/if}
